.headerWrapper {
  background-color: #1a1a2e;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.dots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.dot {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);  
  border-radius: 50%;
  animation: moveDot 20s infinite linear;
}

.header {
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.intro, .content {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.intro.visible, .content.visible {
  opacity: 1;
  transform: translateY(0);
}

.intro.visible {
  transition-delay: 0.2s;
}

.content.visible {
  transition-delay: 0.4s;
}

.name {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #16213e;
  position: relative;
  display: inline-block;
  z-index: 1;
}

.name::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: 10px;
  background: #e94560;
  z-index: -1;
  transform: skew(-5deg);
  transition: all 0.3s ease;
}

.name:hover::before {
  transform: skew(-5deg) translateX(5px) translateY(5px);
}

.title {
  font-size: 1.8rem;
  font-weight: 400;
  color: #e94560;
  margin-bottom: 0.5rem;
}

.summary {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #a9a9a9;
  margin-bottom: 2rem;
}

.contact {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.iconLink {
  color: #fff;
  font-size: 1.8rem;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateY(10px);
}

.visible .iconLink {
  opacity: 1;
  transform: translateY(0);
}

.visible .iconLink:hover {
  color: #e94560;
  transform: translateY(-2px) rotate(5deg) !important;
}

.visible .iconLink:nth-child(1) { transition-delay: 0.1s; }
.visible .iconLink:nth-child(2) { transition-delay: 0.1s; }
.visible .iconLink:nth-child(3) { transition-delay: 0.1s; }

.iconLink[data-email] {
  cursor: pointer;
  overflow: visible;
}

.emailText {
  position: absolute;
  left: 100%;
  background-color: #e94560;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1rem;
  white-space: nowrap;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateX(-20px);
  pointer-events: none;
}

.iconLink[data-email]:hover .emailText {
  opacity: 1;
  transform: translateX(10px);
}

.copyMessage {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.copyMessage.visible {
  opacity: 1;
  visibility: visible;
}

.buttons {
  display: flex;
  gap: 1.5rem;
}

.button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #e94560;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(10px);
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transition: all 0.5s;
}

.button:hover::before {
  left: 100%;
}

.button:hover {
  background-color: #c13e54;
  transform: translateY(-2px);
}

.visible .button {
  opacity: 1;
  transform: translateY(0);
}

.visible .button:nth-child(1) { transition-delay: 0.9s; }
.visible .button:nth-child(2) { transition-delay: 1s; }
.visible .button:nth-child(3) { transition-delay: 1.1s; }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveDot {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100vw, 100vh);
  }
}

.emailContainer {
  position: relative;
  display: inline-block;
}

.emailIcon {
  position: relative;
}

.emailTooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  white-space: nowrap;
  user-select: all;
  margin-left: 10px;
  pointer-events: none;
}

.emailIcon:hover .emailTooltip {
  visibility: visible;
  opacity: 1;
  transform: translateY(-50%) translateX(5px);
  pointer-events: auto;
}

.emailTooltip:hover {
  visibility: visible;
  opacity: 1;
  transform: translateY(-50%) translateX(5px);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0.7);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(233, 69, 96, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0);
  }
}

.pulseButton {
  animation: pulse 2s infinite;
}

.visible .button:nth-child(2) {
  transition-delay: 1s;
  animation: pulseWithColor 2s infinite;
}

.visible .button:hover {
  animation: none;
  transform: translateY(-2px);
  background-color: #c13e54;
}

@keyframes pulseWithColor {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0.7);
    background-color: #e94560;
  }
  
  70% {
    transform: scale(1.15);
    box-shadow: 0 0 20px rgba(233, 69, 96, 0.9);
    background-color: #ff5472;
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0);
    background-color: #e94560;
  }
}

.visible .button:nth-child(2) { 
  transition-delay: 1s;
  animation: pulseWithColor 2s infinite;
}

@keyframes floatAndGlow {
  0% {
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0.4);
  }
  
  50% {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(233, 69, 96, 0.6);
  }
  
  100% {
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0.4);
  }
}

.visible .button:nth-child(3) { 
  transition-delay: 1.1s;
  animation: floatAndGlow 3s ease-in-out infinite;
}

.visible .button:hover {
  animation: none;
  transform: translateY(-2px);
  background-color: #c13e54;
}

@keyframes windowsWave {
  0% {
    transform: translateY(0) rotate(0deg);
    background-color: #e94560;
  }
  
  25% {
    transform: translateY(-6px) rotate(2deg);
    background-color: #ff5472;
    box-shadow: 0 6px 15px rgba(233, 69, 96, 0.8);
  }
  
  75% {
    transform: translateY(4px) rotate(-2deg);
    background-color: #e94560;
    box-shadow: 0 6px 15px rgba(233, 69, 96, 0.8);
  }
  
  100% {
    transform: translateY(0) rotate(0deg);
    background-color: #e94560;
  }
}

.visible .button:nth-child(3) { 
  transition-delay: 1.1s;
  animation: windowsWave 4s ease-in-out infinite;
}

.visible .button:hover {
  animation: none;
  transform: translateY(-2px);
  background-color: #c13e54;
}

@keyframes bounceGlow {
  0% {
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0.4);
    background-color: #e94560;
  }
  
  50% {
    transform: translateY(-8px);
    box-shadow: 0 8px 20px rgba(233, 69, 96, 0.8);
    background-color: #ff5472;
  }
  
  75% {
    transform: translateY(4px);
    box-shadow: 0 4px 15px rgba(233, 69, 96, 0.6);
    background-color: #e94560;
  }
  
  100% {
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0.4);
    background-color: #e94560;
  }
}

.visible .button:nth-child(3) { 
  transition-delay: 1.1s;
  animation: bounceGlow 3s ease-in-out infinite;
}

.visible .button:hover {
  animation: none;
  transform: translateY(-2px);
  background-color: #c13e54;
}

@keyframes gentleFloat {
  0% {
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0.4);
  }
  
  50% {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(233, 69, 96, 0.6);
  }
  
  100% {
    transform: translateY(0);
    box-shadow: 0 0 0 0 rgba(233, 69, 96, 0.4);
  }
}

.visible .button:nth-child(1) { 
  transition-delay: 0.9s;
  animation: gentleFloat 2.5s ease-in-out infinite;
}

@media (max-width: 768px) {
  .header {
    padding: 2rem 0;
  }

  .container {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 1rem;
  }

  .name {
    font-size: 3rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .summary {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .contact {
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .button {
    width: 100%;
    max-width: 250px;
    justify-content: center;
  }

  .emailTooltip {
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    margin-top: 10px;
    margin-left: 0;
  }

  .emailIcon:hover .emailTooltip {
    transform: translateX(-50%) translateY(5px);
  }
}

@media (max-width: 480px) {
  .name {
    font-size: 2.5rem;
  }

  .container {
    padding: 0 1rem;
  }
}
