.searchContainer {
  width: 50%; /* Reduce from 100% to match chatInput width */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start; /* Change from space-between to flex-start */
}


.searchContainer form {
  flex-grow: 1;
  display: flex;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  overflow: hidden;
  background-color: #1a1a1a;
  margin-right: 0; /* Remove the margin-right */
}

.searchInput {
  flex-grow: 1;
  padding: 0.75rem 1rem;
  border: none;
  font-size: 1rem;
  background-color: #1a1a1a;
  color: #ffffff;
  line-height: 1.2; /* Adjust line height for vertical centering */
}

.searchInput::placeholder {
  color: #888888;
}

.searchButton {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchButton:hover {
  background-color: #0056b3;
}

/* ... existing styles ... */

.chatbotContainer {
  width: 100%;
  max-width: 1100px;
  margin: 1rem auto; /* Reduced from 2rem to 1rem */
  margin-left: 0;
  padding: 0 2rem;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; /* Changed from center to flex-start */
}

.chatInput {
  display: flex;
  gap: 0.5rem;
  width: 70%;
  background: #1a1a2e;
  padding: 0.75rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(233, 69, 96, 0.2);
  margin-left: 0;
}

.inputField {
  flex-grow: 1;
  padding: 0.75rem 1.5rem;
  border: none;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.inputField::placeholder {
  color: #a9a9a9;
  transition: color 0.3s ease;
}

.inputField:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.inputField:focus::placeholder {
  color: #e94560;
}

.sendButton {
  background-color: #e94560;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendButton:hover {
  background-color: #c13e54;
  transform: translateY(-2px);
}

.chatWindow {
  position: fixed;
  right: 80px;
  bottom: 20px;
  width: 350px;
  height: 500px;
  background-color: #1a1a2e;
  border: 1px solid rgba(233, 69, 96, 0.2);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
}

.chatMessages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.message {
  margin-bottom: 10px;
  padding: 1rem;
  border-radius: 10px;
  max-width: 85%;
  line-height: 1.4;
}

.user {
  background-color: #e94560;
  color: white;
  align-self: flex-end;
  margin-left: auto;
}

.bot {
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
}

.toggleButton {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #e94560;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(233, 69, 96, 0.4);
  transition: all 0.3s ease;
}

.toggleButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(233, 69, 96, 0.6);
}

/* Custom scrollbar */
.chatMessages::-webkit-scrollbar {
  width: 6px;
}

.chatMessages::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.chatMessages::-webkit-scrollbar-thumb {
  background: #e94560;
  border-radius: 3px;
}

.chatMessages::-webkit-scrollbar-thumb:hover {
  background: #c13e54;
}

/* Loading and error states */
.loading {
  color: #a9a9a9;
  text-align: center;
  padding: 1rem;
}

.error {
  color: #e94560;
  text-align: center;
  padding: 1rem;
  background-color: rgba(233, 69, 96, 0.1);
  border-radius: 5px;
  margin: 1rem;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .chatbotContainer {
    padding: 0 1rem;
    margin: 0.5rem auto;
  }

  .chatInput {
    width: 100%; /* Full width on mobile */
    padding: 0.5rem;
  }

  .inputField {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .sendButton {
    padding: 0.5rem 1rem;
  }

  .chatWindow {
    right: 0;
    left: 0;
    bottom: 70px;
    width: 90%;
    height: 50vh;
    border-radius: 10px 10px 0 0;
    margin: 0 auto;
  }

  .toggleButton {
    right: 10px;
    bottom: 10px;
    width: 45px;
    height: 45px;
    font-size: 1.2rem;
  }

  .message {
    max-width: 90%;
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .chatbotContainer {
    padding: 0 0.5rem;
  }

  .chatInput {
    padding: 0.4rem;
  }

  .inputField {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }

  .sendButton {
    padding: 0.4rem 0.8rem;
  }

  .message {
    max-width: 95%;
    padding: 0.6rem;
    font-size: 0.85rem;
  }

  .chatWindow {
    height: 45vh;
  }
}
