.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.content {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.mainContent {
  flex: 1;
  overflow-y: auto;
}

.sidebar {
  width: 350px;
  flex-shrink: 0;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #ef476f, #d63d62);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #d63d62, #ef476f);
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .app {
    padding: 10px;
  }

  .content {
    flex-direction: column;
    gap: 15px;
  }

  .sidebar {
    width: 100%;
    order: -1; /* Makes education section appear first on mobile */
  }

  .mainContent {
    width: 100%;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .app {
    padding: 5px;
  }

  .content {
    gap: 10px;
    margin-top: 10px;
  }
}
