.education {
  background-color: #1a1a2e;
  color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.sectionTitle {
  font-size: 2.5rem;
  color: #e94560;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 2px solid #e94560;
  padding-bottom: 0.5rem;
}

.educationItem {
  margin-bottom: 1.5rem;
}

.educationItem h3 {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 0.25rem;
}

.educationItem h4 {
  font-size: 1.2rem;
  color: #a9a9a9;
  margin-bottom: 0.25rem;
}

.subTitle {
  font-size: 1.8rem;
  color: #e94560;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid #e94560;
  padding-bottom: 0.5rem;
}

.skillsList, .languagesList, .interestsList, .learningList {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.skillsList li, .languagesList li, .interestsList li, .learningList li {
  background-color: #16213e;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  border: 1px solid #e94560;
}

.skillsList li:hover, .languagesList li:hover, .interestsList li:hover, .learningList li:hover {
  background-color: #e94560;
  transform: translateY(-2px);
}
