.projects {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #f8f8fc;
  color: #fff;
}

.sectionTitle {
  font-size: 3rem;
  color: #16213e;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
  z-index: 1;
}

.sectionTitle::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: 10px;
  background: #e94560;
  z-index: -1;
  transform: skew(-5deg);
  transition: all 0.3s ease;
}

.sectionTitle:hover::before {
  transform: skew(-5deg) translateX(5px) translateY(5px);
}

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.projectCard {
  background-color: #16213e;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  color: #fff;
}

.projectCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(233, 69, 96, 0.2), transparent);
  transition: all 0.5s;
}

.projectCard:hover::before {
  left: 100%;
}

.projectCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.projectCard h3 {
  font-size: 1.4rem;
  color: #e94560;
  margin-bottom: 0.5rem;
}

.projectCard p {
  font-size: 1rem;
  color: #a9a9a9;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #f8f8fc;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  color: #16213e;
}

.modalContent h3 {
  color: #e94560;
  margin-bottom: 1rem;
}

.modalContent p {
  color: #16213e;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #e94560;
  cursor: pointer;
  transition: all 0.3s ease;
}

.closeButton:hover {
  color: #c13e54;
  transform: rotate(90deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.projectCard {
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
}

.projectCard:nth-child(1) { animation-delay: 0.1s; }
.projectCard:nth-child(2) { animation-delay: 0.2s; }
.projectCard:nth-child(3) { animation-delay: 0.3s; }
.projectCard:nth-child(4) { animation-delay: 0.4s; }
.projectCard:nth-child(5) { animation-delay: 0.5s; }

.projectImage {
  max-width: 200px;
  height: auto;
  margin: 15px auto 0;
  display: block;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
