.experience {
  background-color: #f8f8f8;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
  font-size: 1.8rem;
  color: #1a1a2e;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 2px solid #e94560;
  padding-bottom: 0.5rem;
}

.experienceItem {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.experienceItem:last-child {
  border-bottom: none;
}

.experienceItem h3 {
  font-size: 1.3rem;
  color: #1a1a2e;
  margin-bottom: 0.25rem;
  position: relative;
  padding-bottom: 0.5rem;
}



/* Option 2: Gradient line under job title */

.experienceItem h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100px;
  height: 2px;
  background: linear-gradient(to right, #e94560, transparent);
}




.experienceItem h4 {
  font-size: 1.1rem;
  color: #34495e;
  margin-bottom: 0.25rem;
}

.date {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 0.5rem;
}

.subTitle {
  font-size: 1.2rem;
  color: #1a1a2e;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.projectsList {
  list-style-type: none;
  padding-left: 1rem;
}

.projectsList li {
  margin-bottom: 0.5rem;
  position: relative;
}

.projectsList li::before {
  content: '•';
  color: #e94560;
  position: absolute;
  left: -1rem;
}
